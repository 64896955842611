import React from "react";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { AxiosError } from "axios";
import { RouterProvider } from "react-router-dom";
import routers from "@/routes/routers";
import { Toaster } from "@/components/ui/toaster";
import {toast} from "@/hooks/use-toast";

// Determine if the environment is development or production
const isDev = process.env.REACT_APP_NODE_ENV === "development";
const isProd = process.env.REACT_APP_NODE_ENV === "production";

const handleAuthReset = () => {
  localStorage.clear()
};

// Initialize QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (isDev) console.log({ failureCount, error });

        if (failureCount >= 0 && isDev) return false;
        if (failureCount > 3 && isProd) return false;

        return !(
            error instanceof AxiosError &&
            [401, 403].includes(error.response?.status ?? 0)
        );
      },
      refetchOnWindowFocus: isProd,
      staleTime: 10 * 1000, // 10 seconds
    },
    mutations: {
      onError: (error: unknown) => {
        if (error instanceof AxiosError && error.response?.status === 304) {
          toast({
            variant: "destructive",
            title: "Content not modified!",
          });
        } else if (error.code === "ERR_NETWORK") {
          toast({
            variant: "destructive",
            title: "Network Error!",
            description: "Unable to reach the server. Please try again later.",
          });
        }
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) {
          toast({
            variant: "destructive",
            title: "Internal Server Error!",
          });
        } else if (error.code === 'ECONNABORTED') {
          toast({
            variant: "destructive",
            title: "Request timed out!",
          });
        }
         else if (error.code === "ERR_NETWORK") {
           toast({
             variant: "destructive",
             title: "Network Error!",
             description: "Unable to reach the server. Please try again later.",
           });
          handleAuthReset()
         }
      }
    },
  }),
});

const App: React.FC = () => {
  return (
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={routers} />
        <Toaster /> {/* ShadCN Toaster for notifications */}
      </QueryClientProvider>
  );
};

export default App;
