import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import React, {useEffect, useMemo, useRef, useState} from "react";
import { CardContent } from "@mui/material";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {usePyServiceManager} from "@/hooks/use-pyservice";
import {toast} from "@/hooks/use-toast";
import {generateDynamicColumns} from "@/pages/general/ml-models/components/glassnet-columns";
import {GlassNetModelDataTable} from "@/pages/general/ml-models/components/glassnet-table";
import {IconDownload} from "@tabler/icons-react";
import {Cross2Icon, PlusCircledIcon} from "@radix-ui/react-icons";
import {GlassDatabaseTable} from "@/pages/general/glass-database-view/components/glass-database-table";
import {columns} from "@/pages/general/glass-database-view/components/glass-database-columns";
import ScatterChart from "@/pages/general/glass-database-view/components/glass-database-scatter-chart";
import {DataTableToolbar} from "@/pages/general/glass-database-view/components/data-table-toolbar";

interface GlassDatabaseTabsProps {
    databaseData: any
    databaseColumns: any
    isDataFetching: boolean;
    page: number;
    limit: number;
    setPage: (page: number) => void;
    setLimit: (limit: number) => void;
    setSorting: (sorting: any) => void;
    setSearchKey: (searchKey: string) => void;
    searchKey: string;
    glassDatabaseViewTableRef: any
    handleGlassDatabaseViewTableRef: () => void;
    oxides: any[]
    properties: any[]
    xAxis: string;
    yAxis: string;
    setXAxis: (xAxis: string) => void;
    setYAxis: (yAxis: string) => void;
    binSize: number;
    setBinSize: (binSize: number) => void;
    databaseGraphData: any[]
    totalPoints: number;
    isGraphDataFetching:boolean
    graphDataError: any
    databaseDataDownloadURL: string;
    isFetchingDownloadURL: boolean;
    handleDownload: () => void;
}

export function GlassDatabaseTabs({databaseData, databaseColumns, isDataFetching, page, limit, setPage, setLimit, setSorting, setSearchKey, searchKey, glassDatabaseViewTableRef, handleGlassDatabaseViewTableRef, properties, oxides, xAxis, setXAxis, yAxis, setYAxis, binSize, setBinSize, databaseGraphData, totalPoints, isGraphDataFetching, databaseDataDownloadURL, isFetchingDownloadURL, handleDownload}: GlassDatabaseTabsProps) {

    const MemoizedGlassDatabaseTable = React.memo(GlassDatabaseTable);
    const MemoizedScatterChart = React.memo(ScatterChart);

    const memoizedColumns = useMemo(() => {
        return columns(Array.isArray(databaseColumns?.data) ? databaseColumns.data : []);
    }, [databaseColumns]);

    const memoizedData = useMemo(() => {
        return databaseData?.data?.items ?? [];
    }, [databaseData]);

    return (
        <Tabs defaultValue={"table_view"} className="mt-4">
            <TabsList className="flex-1 gap-2 flex-wrap">
                <TabsTrigger value="table_view" onClick={() => handleGlassDatabaseViewTableRef()}>Table View</TabsTrigger>
                <TabsTrigger value="graph_view">Plot Graph</TabsTrigger>
            </TabsList>
            <TabsContent value="table_view" className="w-full">
                <DataTableToolbar
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                    isLoading={isFetchingDownloadURL}
                    databaseDataDownloadURL={databaseDataDownloadURL}
                    handleDownload={handleDownload}
                />
                <div ref={glassDatabaseViewTableRef}>
                    <MemoizedGlassDatabaseTable
                        data={memoizedData}
                        columns={memoizedColumns}
                        isLoading={isDataFetching}
                        pagination={{
                            page,
                            limit,
                            total: databaseData?.data?.pagination?.total_items ?? 0,
                            onPageChange: setPage,
                            onLimitChange: setLimit,
                        }}
                        onSortingChange={setSorting}
                    />
                </div>
            </TabsContent>
            <TabsContent value="graph_view" className="w-full">
                <div>
                    <MemoizedScatterChart
                        oxides={oxides}
                        properties={properties}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        setYAxis={setYAxis}
                        setXAxis={setXAxis}
                        binSize={binSize}
                        setBinSize={setBinSize}
                        databaseGraphData={databaseGraphData}
                        totalPoints={totalPoints}
                        isGraphDataFetching={isGraphDataFetching}
                    />
                </div>
            </TabsContent>
        </Tabs>
    );
}
