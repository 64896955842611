import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import axiosInstance from '@/http/_http';
import { ApiResponse } from '@/utils/response';
import { TransformToCamelCase } from "@/utils/utils";

interface ProfileUpdateRequest {
  lastName: string;
  firstName: string;
  email: string;
  ID: number;
  institution: string;
  roleID: number;
  countryID: number;
  sectorID: number;
  profileStatus: number
}

interface ProfileResponse {
  lastName: string;
  firstName: string;
  email: string;
  ID: number;
  institution: string;
  roleID: number;
  countryID: number;
  sectorID: number;
  profileStatus: number;
}

export function useAccountManager() {

  function useUpdateProfile(): UseMutationResult<ApiResponse<null>, Error, ProfileUpdateRequest> {
    return useMutation<ApiResponse<null>, Error, ProfileUpdateRequest>(
      async ({
               lastName,
               firstName,
               email,
               ID,
               institution,
               roleID,
               countryID,
               sectorID,
               profileStatus,
             }) => {
        const { data: response } = await axiosInstance.put<ApiResponse<null>>(
          '/api/2.0/account/profile',
          {
            last_name: lastName,
            first_name: firstName,
            id: ID,
            email,
            institution,
            role_id: roleID,
            country_id: countryID,
            sector_id: sectorID,
            profile_status: profileStatus
          }
        );
        return response;
      }
    );
  }

  function useFetchProfileByID(ID: number): UseQueryResult<ApiResponse<ProfileResponse>, Error> {
    return useQuery<ApiResponse<ProfileResponse>, Error>(
      ['fetchProfile', ID],
      async () => {
        const { data } = await axiosInstance.get<ApiResponse<ProfileResponse>>(`/api/2.0/account/profile?id=${ID}`);
        return {
          ...data,
          data: TransformToCamelCase(data.data),
        };
      },
      {
        enabled: !!ID,
      }
    );
  }

  return {
    useUpdateProfile,
    useFetchProfileByID,
  };
}


