import { Main } from '@/components/layout/main'
import BreadCrumb from "@/components/breadcrumb";
import {FileUploaderCard} from "@/pages/admin/glass-database-update/components/glass-database-dialog";
const breadcrumbItems = [
    { title: 'Glass Database', link: '/general/glass-database-view-update' },
    { title: 'Update', link: '/admin/glass-database-view-update/update' },
];

export default function GlassDatabaseUpdate() {
  return (
      <Main>
        <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <BreadCrumb items={breadcrumbItems}/>
        </div>
        <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap gap-x-4'>
          <div>
            <h2 className='text-2xl font-bold tracking-tight'>Update Database</h2>
            <p className='text-muted-foreground'>
              Please use below option to upload or update glass databases!
            </p>
          </div>
        </div>
        <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
            <FileUploaderCard />
        </div>
      </Main>
  )
}
