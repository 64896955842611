import { Button } from '@/components/ui/button'
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function MaintenanceError() {

  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('is_user_logged_in') === 'true';

    if (!isLoggedIn) {
      navigate('/sign-in', { replace: true });
    } else {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  return (
    <div className='h-svh'>
      <div className='m-auto flex h-full w-full flex-col items-center justify-center gap-2'>
        <h1 className='text-[7rem] font-bold leading-tight'>503</h1>
        <span className='font-medium'>Website is under maintenance!</span>
        <p className='text-center text-muted-foreground'>
          The site is not available at the moment. <br />
          We'll be back online shortly.
        </p>
        <div className='mt-6 flex gap-4'>
          <Button variant='outline'>Learn more</Button>
        </div>
      </div>
    </div>
  )
}
