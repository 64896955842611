import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "react-query";
import axiosInstance from '@/http/_http';
import { ApiResponse } from "@/utils/response";


interface User {
    id: number;
    isAdmin: boolean ;
}

interface UserData {
    first_name: string;
    last_name: string;
    email: string;
    institution: string;
    sector_id: number;
    role_id: number;
    country_id: number;
    profile_status: number;
    role_label: string;
    sector_label: string;
    country_label: string;
    approval_status: number;
    is_email_verified: boolean;
    is_admin: boolean;
    created_at: string; // ISO 8601 format for dates (e.g., "2023-01-01T00:00:00Z")
    user_type: number;
}

interface GenericResponse {

}

export const usePyServiceManager = () => {

    const useFetchGlassNetModel = (formattedCompositions) => {
        return useQuery(
            ["fetch-glassnet-prediction", formattedCompositions],
            async () => {
                const formattedData = formattedCompositions.reduce((acc, comp) => {
                    acc["composition"] = acc["composition"] || {};
                    acc["composition"][comp.name] = comp.range;
                    return acc;
                }, {});

                const { data: response } = await axiosInstance.post(
                    `${process.env.REACT_APP_GLASSNET_MODEL_MULTI}`,
                    [formattedData],
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                return response;
            },
            {
                enabled: formattedCompositions.length > 0, // Only fetch when there are compositions
            }
        );
    };

    return {
        useFetchGlassNetModel,
    };
};
