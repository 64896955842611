import {useMutation, UseMutationResult, useQuery, UseQueryResult} from 'react-query';
import axiosInstance from '@/http/_http';
import { ApiResponse } from '@/utils/response';
import {TransformToCamelCase} from "@/utils/utils";


interface Response {

}

export function usePublicManager() {

  function useFetchCountries(): UseQueryResult<ApiResponse<{
    id: number;
    label: string;
  }[]>, Error> {
    return useQuery<ApiResponse<{
      id: number;
      label: string;
    }[]>, Error>('fetchCountries', async () => {
      const { data } = await axiosInstance.get<ApiResponse<{
        id: number;
        label: string;
      }[]>>('/api/2.0/public/countries');
      return data;
    });
  }

  function useFetchRoles(): UseQueryResult<ApiResponse<{
    id: number;
    label: string;
  }[]>, Error> {
    return useQuery<ApiResponse<{
      id: number;
      label: string;
    }[]>, Error>('fetchRoles', async () => {
      const { data } = await axiosInstance.get<ApiResponse<{
        id: number;
        label: string;
      }[]>>('/api/2.0/public/roles');
      return data;
    });
  }

  function useFetchSectors(): UseQueryResult<ApiResponse<{
    id: number;
    label: string;
  }[]>, Error> {
    return useQuery<ApiResponse<{
      id: number;
      label: string;
    }[]>, Error>('fetchSectors', async () => {
      const { data } = await axiosInstance.get<ApiResponse<{
        id: number;
        label: string;
      }[]>>('/api/2.0/public/sectors');
      return data;
    });
  }


  function useMutateContactUs(): UseMutationResult<ApiResponse<null>, Error, {firstName: string, lastName: string, email: string, phoneNumber: string, message: string}> {
    return useMutation<ApiResponse<null>, Error, {firstName: string, lastName: string, email: string, phoneNumber: string, message: string}>(
        async ({
                 lastName,
                 firstName,
                 email,
                 phoneNumber,
                 message,
               }) => {
          const { data: response } = await axiosInstance.post<ApiResponse<null>>(
              '/api/2.0/public/contact-us',
              {
                last_name: lastName,
                first_name: firstName,
                email: email,
                phone_number: phoneNumber,
                message: message,
              }
          );
          return response;
        }
    );
  }

  return {
    useFetchCountries,
    useFetchRoles,
    useFetchSectors,
    useMutateContactUs
  };
}


