import { ColumnDef } from '@tanstack/react-table'
import { cn } from '@/lib/utils'
import { Checkbox } from '@/components/ui/checkbox'
import LongText from '@/components/long-text'
import {userTypes} from '../data/data'
import { UserData } from '../data/schema'
import { DataTableColumnHeader } from './data-table-column-header'
import ApprovalStatusCell from "@/pages/admin/users/components/approval-status-cell";

export const columns = (refetch) => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
        className='translate-y-[2px]'
      />
    ),
    meta: {
      className: cn(
        'sticky md:table-cell left-0 z-10 rounded-tl',
        'bg-background transition-colors duration-200 group-hover/row:bg-muted group-data-[state=selected]/row:bg-muted'
      ),
    },
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
        className='translate-y-[2px]'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'fullName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: ({ row }) => {
      const { first_name, last_name } = row.original
      const fullName = `${first_name} ${last_name}`
      return <LongText className='max-w-36'>{fullName}</LongText>
    },
    meta: { className: 'w-36' },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Email' />
    ),
    cell: ({ row }) => (
      <div className='w-fit text-nowrap'>{row.getValue('email')}</div>
    ),
  },
  {
    accessorKey: 'institution',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Institution' />
    ),
    cell: ({ row }) => <div>{row.getValue('institution')}</div>,
    enableSorting: false,
  },
  {
    accessorKey: 'role_label',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Role' />
    ),
    cell: ({ row }) => <div>{row.getValue('role_label')}</div>,
    enableSorting: false,
  },
  {
    accessorKey: "approval_status",
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Approval Status" />
    ),
    cell: ({ row }) => {
      const { approval_status, id } = row.original; // Assuming `id` uniquely identifies the row

      return (
          <ApprovalStatusCell
              approvalStatus={approval_status}
              userId={id}
              refetch={refetch}
          />
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'sector_label',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Sector' />
    ),
    cell: ({ row }) => <div>{row.getValue('sector_label')}</div>,
    enableSorting: false,
  },
  {
    accessorKey: 'user_type_label',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='User Type' />
    ),
    cell: ({ row }) => {
      const { user_type_label } = row.original
      const userType = userTypes.find(({ label }) => label === user_type_label)

      if (!userType) {
        return null
      }

      return (
          <div className='flex gap-x-2 items-center'>
            {userType.icon && (
                <userType.icon size={16} className='text-muted-foreground' />
            )}
            <span className='capitalize text-sm'>{userType.label}</span>
          </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
    enableSorting: false,
    enableHiding: false,
  },

  // {
  //   id: 'actions',
  //   cell: DataTableRowActions,
  // },
]
