import React, { useState } from "react";
import { cn } from "@/lib/utils";
import { approvalStatusStyles } from "../data/data";
import { useAdminManager } from "@/hooks/use-admin-hook";
import {toast} from "@/hooks/use-toast";
import {UserApprovalStatus} from "@/pages/admin/users/data/schema";
import {UserData} from "@/components/Profile";

interface ApprovalStatusCellProps {
    approvalStatus: number;
    userId: number;
    refetch: () => void;
}

const ApprovalStatusCell: React.FC<ApprovalStatusCellProps> = ({
                                                                   approvalStatus,
                                                                   userId,
                                                                   refetch,
           }) => {

const { mutate: updateApprovalStatus, isLoading } =
        useAdminManager().useUpdateApprovalStatus();

    const handleApprovalStatusChange = (newStatus: string) => {
        //const previousStatus = approvalStatus; // Store previous status for rollback

        updateApprovalStatus(
            { user_id: userId, approval_status: Number(newStatus) },
            {
                onSuccess: () => {
                    toast({
                        variant: "default",
                        title: "User Update.",
                        description:`Approval status updated successfully`,
                    });
                    refetch()
                },
                onError: (error) => {
                    toast({
                        variant: "destructive",
                        title: "User Update.",
                        description:`Failed to update approval status`,
                    });
                },
            }
        );
    };

    const badgeColor = approvalStatusStyles.get(String(approvalStatus) as UserApprovalStatus); // Use local status for styling

    return (
        <div className="flex space-x-2 items-center">
            <select
                value={approvalStatus} // Use local state for value
                onChange={(e) => handleApprovalStatusChange(e.target.value)}
                className={cn(
                    "capitalize border rounded-md p-1",
                    badgeColor,
                    isLoading && "opacity-50 cursor-not-allowed"
                )}
                disabled={isLoading} // Prevent interactions while loading
            >
                <option value="1">Requested</option>
                <option value="2">Approved</option>
                <option value="3">Declined</option>
            </select>
        </div>
    );
};

export default ApprovalStatusCell;
