import { cn } from "@/lib/utils";
import { MobileSidebar } from "./mobile-sidebar";
import { UserNav } from "./user-nav";
import { useNavigate } from "react-router-dom";
import images from "@/assets";

export default function Header() {

  const navigate = useNavigate();

  return (
      <header className="sticky top-0 z-20 border-b bg-background/95 backdrop-blur supports-backdrop-blur:bg-background/60">
        <nav className="h-14 flex items-center justify-between px-4">
          <div
              className=" flex-row items-center justify-start hover:cursor-pointer lg:flex hidden"
              onClick={() => {
                navigate("/dashboard")
              }}>
            <img src={images.doeemlogo} alt="EM Icon" className="h-12 w-60"/>
            <img src={images.srnllogo} alt="SRNL Icon" className="h-10 w-24"/>
            <img src={images.clemsonlogo} alt="em logo" className="h-10 w-24"/>
            <img src={images.alfredlogo} alt="SRNL Icon" className="h-10 w-24"/>
            <img src={images.northwestlogo} alt="em logo" className="h-10 w-24"/>
          </div>

          {/* Mobile Sidebar Toggle */}
          <div className={cn("block lg:!hidden")}>
            <MobileSidebar/>
          </div>

          <div className="flex items-center gap-2">
            <UserNav/>
          </div>
        </nav>
      </header>
  );
}
