import {
    elements,
    period1Elements,
    period2LeftMostElements,
    period2RightMostElements,
    period3LeftMostElements,
    period3RightMostElements,
    lanthanidesElements,
    actinidesElements,
} from "@/pages/general/glass-database-view/data/periodic-table-data";

import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import {PreprocessOxides} from "@/utils/utils";
import {useState} from "react";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "@/components/ui/command";
import {CheckIcon} from "@radix-ui/react-icons";
import {cn} from "@/lib/utils";
import * as React from "react";
import {LoadingSpinner} from "@/components/loader/spinner";

const periodColorMap = {
    1: "bg-red-700 hover:bg-red-600 cursor-pointer",
    2: "bg-green-700 hover:bg-green-600 cursor-pointer",
    3: "bg-blue-700 hover:bg-blue-600 cursor-pointer",
    4: "bg-yellow-700 hover:bg-yellow-600 cursor-pointer",
    5: "bg-purple-700 hover:bg-purple-600 cursor-pointer",
    6: "bg-pink-700 hover:bg-pink-600 cursor-pointer",
    7: "bg-indigo-700 hover:bg-indigo-600 cursor-pointer",
    99: "bg-gray-700 hover:bg-gray-600 cursor-pointer",
};

interface PeriodicTableProps {
    optionalElement?: React.ReactNode;
    databaseElements: any[]
    databaseOxides: any[],
    handleScrollToOxideComponent: () => void;
    setOxideKey: (key: string) => void;
    isDatabaseElementsFetching: boolean
}

export default function PeriodicTable({ setOxideKey, optionalElement , databaseElements, databaseOxides, handleScrollToOxideComponent, isDatabaseElementsFetching}: PeriodicTableProps) {
    const filteredElements = elements.filter(
        (el) => !["Lanthanides", "Actinides"].includes(el.Family) && ![1, 2, 3].includes(el.Period)
    );

    const oxideMap = PreprocessOxides(databaseOxides);

    const sortedByPeriods = filteredElements.reduce((acc, el) => {
        acc[el.Period] = acc[el.Period] || [];
        acc[el.Period].push(el);
        return acc;
    }, {} as Record<number, any[]>);

    const handleElementClick = (key: string) => {
        setOxideKey(key)
        handleScrollToOxideComponent()
    }

    const renderElements = (elements: any[], period: number | string) => {
        return elements.map((el) => {
            const PopoverWithState = () => {
                const [isOpen, setIsOpen] = useState(false); // State for this specific popover
                const [value, setValue] = useState();

                const isDisabled = !databaseElements.some((dbEl) => dbEl.element_name === el.Symbol);
                const elementOxides = oxideMap[el.Symbol.toLowerCase()] || [];

                return (
                    <Popover open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
                        <PopoverTrigger asChild>
                            <Card
                                className={`text-white text-center ${
                                    isDisabled
                                        ? "bg-gray-300 cursor-not-allowed"
                                        : periodColorMap[el.Period]
                                } min-w-8 min-h-8 max-w-10 max-h-10 flex items-center justify-center rounded-md`}
                                onClick={() => !isDisabled && setIsOpen(true)} // Open popover
                            >
                                <CardHeader>
                                    <div className="flex flex-col items-start gap-0">
                                        <CardTitle
                                            className={`text-sm font-light text-center ${
                                                isDisabled ? "text-gray-500" : ""
                                            }`}
                                        >
                                            {!isDatabaseElementsFetching && (el.Symbol)}
                                            {isDatabaseElementsFetching && (
                                                <div className=" flex items-center justify-center bg-opacity-75 z-10">
                                                    <LoadingSpinner/>
                                                </div>
                                            )}
                                        </CardTitle>
                                    </div>
                                </CardHeader>
                            </Card>
                        </PopoverTrigger>
                        <PopoverContent className="w-40 p-2 relative w-full">
                            {/* Close Button */}
                            <button
                                className="absolute top-0 right-0 p-1 text-gray-500 hover:text-gray-800 rounded-md focus:outline-none"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering other click events
                                    setIsOpen(false); // Close the popover
                                }}
                            >
                                ✕
                            </button>
                            <Command>
                                <CommandInput placeholder="Search oxide..." className="h-8" />
                                <CommandEmpty>No oxides found.</CommandEmpty>
                                <CommandGroup>
                                    <CommandList>
                                        {elementOxides?.map((oxide) => (
                                            <CommandItem
                                                key={oxide.oxide_name}
                                                onSelect={() => {
                                                    handleElementClick(oxide.oxide_name);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                <div className="flex items-center cursor-pointer">
                                                    <span className="text-sm text-gray-600">{oxide.oxide_name}</span>
                                                    {oxide.min_value !== undefined && oxide.max_value !== undefined && (
                                                        <span
                                                            className="ml-2 text-xs font-light text-muted-foreground/90">
                                                        ({oxide.min_value} to {oxide.max_value})
                                                      </span>
                                                    )}
                                                </div>
                                                <CheckIcon
                                                    className={cn(
                                                        "ml-auto h-4 w-4",
                                                        value === oxide.oxide_name ? "opacity-100" : "opacity-0",
                                                    )}
                                                />
                                            </CommandItem>
                                        ))}
                                    </CommandList>
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                );
            };

            return <PopoverWithState key={el.Symbol}/>;
        });
    };

    return (
        <Card
            className="p-6 flex-col items-center gap-4 hidden sm:flex"
            style={{
                maxWidth: `${Math.max(period2LeftMostElements.length, period3LeftMostElements.length) * 25}rem`,
            }}
        >
            {optionalElement && <div className="mt-5">{optionalElement}</div>}
            {/*<PeriodicTableToolbar searchKey={""} onSearchChange={() => {}} setGlassDatabaseID={setGlassDatabaseID}/>*/}
            <div className="">
                <div className="flex justify-between gap-1 w-full">
                    {renderElements(period1Elements, 1)}
                </div>

                {[period2LeftMostElements, period3LeftMostElements].map((leftElements, idx) => (
                    <div key={idx} className="flex justify-between w-full">
                        <div className="flex gap-1">
                            {renderElements(leftElements, idx + 2)}
                        </div>
                        <div className="flex gap-1">
                            {renderElements(
                                idx === 0 ? period2RightMostElements : period3RightMostElements,
                                idx + 2
                            )}
                        </div>
                    </div>
                ))}

                {Object.entries(sortedByPeriods).map(([period, elements]) => (
                    <div key={period} className="flex w-full">
                        {renderElements(elements, Number(period))}
                    </div>
                ))}

                {[lanthanidesElements, actinidesElements].map((group, idx) => (
                    <div key={idx} className="flex justify-center w-full">
                        {renderElements(group, 99)}
                    </div>
                ))}
            </div>
        </Card>
    );
}
