import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import axiosInstance from '@/http/_http';
import { ApiResponse } from '@/utils/response';

interface SignInResponse {
  id: number;
  email: string;
  isAdmin: boolean;
  isEmailVerified: boolean;
  accessToken: string;
  refreshToken: string;
}

interface Country {
  id: number;
  label: string;
}

interface SignUpRequest {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  institution: string;
  reason: string
  roleID: number;
  countryID: number;
  sectorID: number;
  identityProviderID?: number; // Optional, defaults to 0
  appType?: number; // Optional, defaults to 0
}

interface ProfileUpdateRequest {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  institution: string;
  roleID: number;
  countryID: number;
  sectorID: number;
  professionID: number;
}

interface SignUpResponse {
  id: number;
  email: string;
  message: string; // Add relevant fields as needed
}

interface ProfileUpdateResponse {
  id: number;
  email: string;
  message: string; // Add relevant fields as needed
}

export function useAuthManager() {

  // 1) The "sign in" mutation.
  //    React Query sees this as an action that modifies server state
  //    (creating a session/token, etc.).
  function useSignInMutation(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { email: string; password: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { email: string; password: string }>(
      async ({ email, password }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/sign-in', { email, password });
        return response;
      }
    );
  }

  function useSignInWithGoogleMutation(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { code: string, providerID: number, redirectURI: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { code: string, providerID: number, redirectURI: string }>(
      async ({ code, providerID, redirectURI }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/sign-in/oauth2', { code: code, identity_provider_id: providerID, redirect_uri: redirectURI, app_type: 2 });
        return response;
      }
    );
  }

  function useResendVerification(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { email: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { email: string }>(
      async ({ email }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/resend-email-verification', { email });
        return response;
      }
    );
  }

  function useEmailVerify(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { token: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { token: string }>(
      async ({ token }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/verify-email', { email_verification_token:token  });
        return response;
      }
    );
  }

  function useForgotPassword(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { email: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { email: string }>(
      async ({ email }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/forgot-password', { email:email  });
        return response;
      }
    );
  }

  function useResetPassword(): UseMutationResult<
    ApiResponse<SignInResponse>,
    Error,
    { password: string, token: string }
  > {
    return useMutation<ApiResponse<SignInResponse>, Error, { token: string, password: string }>(
      async ({ token, password }) => {
        const { data: response } = await axiosInstance.post('/api/2.0/auth/reset-password', { reset_token:token, new_password: password });
        return response;
      }
    );
  }

  function useSignUp(): UseMutationResult<ApiResponse<SignUpResponse>, Error, SignUpRequest> {
    return useMutation<ApiResponse<SignUpResponse>, Error, SignUpRequest>(
      async ({
               lastName,
               firstName,
               email,
               password,
               institution,
                 reason,
               roleID,
               countryID,
               sectorID,
               identityProviderID = 1,
               appType = 2,
             }) => {
        const { data: response } = await axiosInstance.post<ApiResponse<SignUpResponse>>(
          '/api/2.0/auth/sign-up',
          {
            last_name: lastName,
            first_name: firstName,
            email,
            password,
            institution,
            reason,
            role_id: roleID,
            country_id: countryID,
            sector_id: sectorID,
            identity_provider_id: identityProviderID,
            app_type: appType,
          }
        );
        return response;
      }
    );
  }

  function useUpdateProfile(): UseMutationResult<ApiResponse<ProfileUpdateResponse>, Error, ProfileUpdateRequest> {
    return useMutation<ApiResponse<ProfileUpdateResponse>, Error, ProfileUpdateRequest>(
      async ({
               lastName,
               firstName,
               email,
               password,
               institution,
               roleID,
               countryID,
               sectorID,
               professionID,
             }) => {
        const { data: response } = await axiosInstance.post<ApiResponse<ProfileUpdateResponse>>(
          '/api/2.0/account/profile',
          {
            last_name: lastName,
            first_name: firstName,
            email,
            password,
            institution,
            role_id: roleID,
            country_id: countryID,
            sector_id: sectorID,
            profession_id: professionID,
          }
        );
        return response;
      }
    );
  }


  return {
    useSignInMutation,
    useSignInWithGoogleMutation,
    useResendVerification,
    useEmailVerify,
    useForgotPassword,
    useResetPassword,
    useSignUp,
    useUpdateProfile,
  };
}


